import { makeAutoObservable, toJS } from 'mobx'
import { openApi } from '../../helpers/request'
import i from '../../translations/i'
import __select from '../../helpers/select_entity/__select.controller'
import { __cpl } from '../catalog/custom_product_lists/__cpl'
import { FormEvent } from 'react'
import { notification } from 'antd'

class __designer {
    pagination = {
        limit: 10,
        page: 1,
        total: 0
    }

    list: any[] = []

    item: any = {}

    place: string = 'home'
    selectedWarehouse: number = 0
    modalOpened = false
    selectedType = ''

    openedBlocks: any = {
        block: []
    }
    selectedMediaBlock: number = 0
    selectedMediaBlockRealID: number = 0
    selectedMediaType = ''

    isOnBoarding = false
    onBoardingType = ''

    constructor() {
        makeAutoObservable(this)
    }

    getList(type: string = 'screens') {
        let uri = ''
        if(type === 'screens') {
            uri = `/screens/?place=${this.place}`
        } else {
            let isOnboarding = type === 'onboarding'
            uri = `/onboarding/?is_onboarding=${Number(isOnboarding)}`
        }

        if(this.selectedWarehouse > 0) {
            uri += `&warehouse_id=${this.selectedWarehouse}`
        }

        openApi('get', uri)
            .then(result => {
                console.log(result.data)
                this.list = result.data
            })
            .catch(error => console.log(error.response))
    }

    getOne(screenID: number | string) {
        openApi('get', `/screens/${screenID}/`)
            .then(result => {
                this.item = result.data
            })
            .catch(error => console.log(error.response))
    }

    deleteOne(type: string, blockID: number, child:boolean = false) {
        let uri = ''
        if(type === 'onboarding') {
            uri = `/onboarding/items/${blockID}`
        } else {
            uri = `/screens/${type}/${blockID}`
            if(child) {
                uri = `/screens/items/${type}/${blockID}`
            }
        }

        openApi('delete', uri)
            .then(() => {
                if(this.onBoardingType !== '') {
                    this.getList(this.onBoardingType)
                } else {
                    this.getList()
                }
            })
            .catch(error => console.log(error.response))

    }

    save(e: FormEvent | null = null) {
        if(e) e.preventDefault()

        let hasError = false
        let uri = `/screens/${this.place}`

        const data = new FormData()

        if(this.selectedMediaType && this.selectedMediaType !== 'story_element') {
            data.append('media_type', this.selectedMediaType)
        }

        if(this.selectedType === 'onboarding')
            data.append('display_image', String(Number(this.item.element.display_image)))

        if(this.selectedMediaBlock > 0 && this.selectedMediaType === 'story_element'){
            data.append('item_id', String(this.selectedMediaBlock))
            data.append('display_image', String(Number(this.item.element.display_image)))

            uri = '/screens/media/story'

            if(this.item.element.id > 0){
                data.append('id', this.item.element.id)
            }
        } else if(this.selectedMediaBlock > 0 && this.onBoardingType === '') {
            data.append('block_id', String(this.selectedMediaBlockRealID))
            data.append('block_type', 'media')

            uri = '/screens/media'

            if(this.item.element.id > 0){
                data.append('item_id', this.item.element.id)
            }
        } else {
            if(this.selectedType !== 'onboarding') {
                data.append('block_type', this.selectedType)

                if(this.item?.element?.id > 0){
                    data.append('block_id', this.item.element.id)
                } else if(this.item.sorting_id > 0) {
                    data.append('block_id', this.item.sorting_id)
                }
            } else {
                uri = '/onboarding/'
                data.append('onboarding', `${Number(this.isOnBoarding)}`)

                if(this.item?.element?.id > 0){
                    uri += this.item.element.id
                }
            }
        }

        if(this.selectedWarehouse > 0){
            data.append('warehouse_id', String(this.selectedWarehouse))
        }

        if(['products', 'media', 'story'].includes(this.selectedType)){
            data.append('type', this.item.element.type)
            if(this.selectedType !== 'story') {
                data.append('view_type', this.item.element.view_type)
            }
        }

        ['name', 'label', 'label_color', 'text', 'text_color', 'button_text', 'button_text_color', 'button_color'].map((key: string) => {
            if(this.item?.element?.[key]) data.append(key, this.item.element[key])
            else if(['video'].includes(this.selectedType)) {
                if(['text'].includes(key)) hasError = true
            }
        })

        if(this.selectedType === 'text' && this.item.element.value) {
            data.append('value', this.item.element.value)
        }

        if(this.selectedType === 'block') {
            if(this.item.element.label === '' && this.item.element.button_text === '' && this.item.element.name === '') {
                hasError = true
            }
        }

        if(this.selectedMediaBlock > 0){
            if(this.item.element.file.length > 0) {
                data.append('type_file', this.item.element.file[0].type_file ?? '')

                if(this.item.element.file[0].uri)
                    data.append('uri', this.item.element.file[0].uri)

                if(this.item.element.file.uri) {
                    data.append('file', this.item.element.file.uri)
                } else {
                    data.append('file', this.item.element.file[0])
                }
            } else {
                hasError = true
            }
        }

        if(this.selectedType === 'onboarding') {
            if(this.item.element.file.uri){
                data.append('file', this.item.element.file.uri)
            } else {
                data.append('file', this.item.element.file[0])
            }
        }

        if(this.selectedType === 'products'){
            if(__cpl.selectedLists[0] > 0){
                data.append('custom_list_id', __cpl.selectedLists[0])
            } else {
                hasError = true
            }
        }

        if(__select.select.type !== '') {
            data.append('refer_type', __select.select.type)

            if(__select.select.type === 'custom_product_list'){
                if(__cpl.selectedLists[0] > 0) {
                    data.append('refer_id', __cpl.selectedLists[0])
                } else {
                    hasError = true
                }
            } else {
                if(__select.select.value || (__select.select.type === 'category' && __select.select.value === '')) {
                    if(__select.select.type === 'url' || __select.select.type === 'url_blank'){
                        data.append('refer_value', __select.select.value)
                    } else {
                        data.append('refer_id', __select.select.value)
                    }
                }
            }
        }

        data.append('enabled', String(Number(this.item.enabled)))
        data.append('button', String(Number(this.item.element.button)))

        if(hasError) {
            return notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.data')
            })
        }


        openApi('post', uri, data)
            .then(result => {
                this.closeModal()
                if(this.onBoardingType !== '') {
                    this.getList(this.onBoardingType)
                } else {
                    this.getList()
                }
                notification.success({
                    message: i.t('def:success.success'),
                    description: i.t('def:success.edit')
                })
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    setPlace(place: string) {
        this.place = place
        this.getList()
    }

    setWarehouse(warehouseID: number, type: string = 'screens') {
        this.selectedWarehouse = warehouseID
        this.getList(type)
    }

    setItem(id: number|null, blockID: number = 0, type: string = '', parentID: number = 0) {
        if(blockID > 0){
            this.selectedMediaBlock = blockID
            this.selectedMediaBlockRealID = parentID
            this.selectedMediaType = type
        }

        if(id === 0) {
            this.item = {
                id: 0,
                enabled: true,
                sorting_id: 0,
                element: {
                    name: '',
                    label: '',
                    label_color: '',
                    display_image: 1,
                    file: [],
                    text: '',
                    text_color: '',
                    button: false,
                    button_text: '',
                    button_text_color: '',
                    button_color: '',
                    refer: [],
                    list: [],
                    type: '',
                    items: [],
                    view_type: 1
                }
            }

            if(this.selectedType === 'media') {
                this.item.element.type = 'banner'
            }

            if(this.selectedType === 'story') {
                this.item.element.type = 1
            }

            __select.setType('')
        } else {
            if(blockID > 0) {
                this.selectedType = type

                let item: any = {}

                if(type === 'story_element') {
                    const parentIndex = this.list.findIndex((el: any) => el.sorting_id === parentID)
                    const childIndex = this.list[parentIndex].element.items.findIndex((el: any) => el.id === blockID)
                    const storyIndex = this.list[parentIndex].element.items[childIndex].stories.findIndex((el: any) => el.id === id)
                    item = toJS(this.list[parentIndex].element.items[childIndex].stories[storyIndex])
                } else if(type === 'onboarding') {
                    const parentIndex = this.list.findIndex((el: any) => el.id === blockID)
                    item = toJS(this.list[parentIndex])

                    console.log(parentIndex, item)
                } else {
                    const parentIndex = this.list.findIndex((el: any) => el.sorting_id === blockID)
                    const childIndex = this.list[parentIndex].element.items.findIndex((el: any) => el.id === id)
                    item = toJS(this.list[parentIndex].element.items[childIndex])
                }



                this.item = {
                    id: item.id,
                    enabled: item.enabled,
                    element: item
                }

                if(item.file && Object.keys(item.file).length > 0) {
                    this.item.element.file = [{
                        uid: item.file.id,
                        status: 'done',
                        name: item.file.entity_type,
                        url: `${item.file.base}${item.file.uri}`,
                        uri: item.file.uri,
                        type_file: item.file.type
                    }]
                } else {
                    this.item.element.file = []
                }

                if(item.refer?.type) {
                    __select.setType(item.refer.type, item.refer.value)

                    if(item.refer.type === 'custom_product_list') {
                        __cpl.selectedLists = [item.refer.value]
                    }

                    __select.select.element = item.refer.element ?? null
                }
            } else {
                const index = this.list.findIndex((el: any) => el.sorting_id === id)
                this.item = toJS(this.list[index])

                if(this.list[index].element && this.list[index].element.value) {
                    // this.item.element.value = this.item.element.value ? this.item.element.value.replace(`<br>`, '<p>&nbsp;</p>') : this.item.element.value
                    this.item.element.value = this.list[index].element.value ?? ''
                }

                if(this.item.type === 'products') {
                    __cpl.selectedLists = [this.item.element.list.id]
                } else if(this.item.type === 'block') {
                    let t = this.item.element.refer.type
                    let v = this.item.element.refer.value
                    let e = this.item.element.refer.element ?? null
                    if(t === 'custom_product_list'){
                        __cpl.selectedLists = [v]
                    } else {
                        __select.setType(t, v)
                        __select.select.element = e
                    }
                }
            }
        }
    }

    openModal(type: any, id: number = 0, elementID: number|null = null, parentID: number = 0) {
        this.modalOpened = true
        this.selectedType = type

        if(elementID === null) {
            this.setItem(id)
        } else {
            this.setItem(elementID, id, type, parentID)
        }

        if(type === 'products'){
            __cpl.getList()
        }


    }

    closeModal() {
        this.modalOpened = false
        __cpl.selectedLists = []
        setTimeout(() => {
            this.selectedType = ''
            this.selectedMediaBlock = 0
        }, 300)
    }

    toggleActive(type: any, id: number = 0, elementID: number|null = null, parentID: number = 0) {
        this.selectedType = type
        if(elementID === null) {
            this.setItem(id)
        } else {
            this.setItem(elementID, id, type, parentID)
        }
        this.item.enabled = !this.item.enabled
        this.save()
    }

    getBlockNameByType(type: string, subType: string = '') {
        if(type === 'products'){
            return i.t('constructor:filter.blocks.items.ksBlockProduct')
        } else if(type === 'media') {
            let r = i.t('def:Media Block')
            if(subType) {
                r += ' ('
                if(subType === 'story') {
                    r += i.t('def:Stories')
                } else if(subType === 'video') {
                    r += i.t('def:Video')
                } else if(subType === 'banner') {
                    r += i.t('def:Banners')
                }
                r += ')'
            }
            return r
        } else if(type === 'block') {
            return i.t('constructor:filter.blocks.items.ksButtonText')
        } else if(type === 'search') {
            return i.t('constructor:filter.blocks.items.search')
        } else if(type === 'lastorder') {
            return i.t('constructor:filter.blocks.items.order')
        } else if(type === 'buttons') {
            return i.t('constructor:filter.blocks.items.buttons')
        } else if(type === 'text') {
            return i.t('constructor:filter.blocks.items.text')
        }
    }

    getReferNameByType(type: string) {
        if(type === 'category') {
            return i.t('def:selectTypes.productCategory')
        } else if(type === 'product_group') {
            return i.t('def:Product Group')
        } else if(type === 'cart') {
            return i.t('def:selectTypes.cart')
        } else if(type === 'favorite') {
            return i.t('def:selectTypes.favorite')
        } else if(type === 'cabinet') {
            return i.t('def:selectTypes.cabinet')
        } else if(type === 'home') {
            return i.t('def:selectTypes.home')
        } else if(type === 'news') {
            return i.t('def:selectTypes.news')
        } else if(type === 'product') {
            return i.t('def:selectTypes.product')
        } else if(type === 'custom_product_list') {
            return i.t('def:Products List')
        } else if(type === 'contacts') {
            return i.t('def:selectTypes.contacts')
        } else if(type === 'place') {
            return i.t('def:selectTypes.depart')
        } else if(type === 'feedback') {
            return i.t('def:selectTypes.feedback')
        } else if(type === 'forms') {
            return i.t('def:selectTypes.questioning')
        } else if(type === 'url') {
            return i.t('def:selectTypes.url')
        } else if(type === 'url_blank') {
            return i.t('def:selectTypes.url_blank')
        } else if(type === 'jivo') {
            return i.t('def:selectTypes.jivo')
        } else if(type === 'orders') {
            return i.t('def:selectTypes.orders')
        } else if(type === 'notifications') {
            return i.t('def:selectTypes.notifications')
        } else if(type === 'faq') {
            return i.t('def:selectTypes.faq')
        } else if(type === 'promo') {
            return i.t('def:selectTypes.promo')
        } else if(type === 'purchased_product') {
            return i.t('def:selectTypes.purchased_product')
        } else if(type === 'places_group') {
            return i.t('def:Places Group')
        } else {
            return i.t('def:selectTypes.none')
        }
    }

    getBannerViewTypeName(type: number|string) {
        if(type == 1) {
            return i.t('constructor:config.slider.items.one')
        } else if(type == 2) {
            return i.t('constructor:config.slider.items.two')
        } else if(type == 3) {
            return i.t('constructor:config.slider.items.three')
        } else if(type == 4) {
            return i.t('constructor:config.slider.items.four')
        } else if(type == 5) {
            return i.t('constructor:config.slider.items.five')
        } else if(type == 6) {
            return i.t('constructor:config.slider.items.six')
        } else if(type == 7) {
            return i.t('constructor:config.slider.items.seven')
        } else if(type == 8) {
            return i.t('constructor:config.slider.items.eight')
        } else if(type == 9) {
            return i.t('constructor:config.slider.items.nine')
        } else if(type == 10) {
            return i.t('constructor:config.slider.items.ten')
        } else if(type == 11) {
            return i.t('constructor:config.slider.items.eleven')
        } else if(type == 12) {
            return i.t('constructor:config.slider.items.twelve')
        } else if(type == 13) {
            return i.t('constructor:config.slider.items.thirteen')
        } else if(type == 14) {
            return i.t('constructor:config.slider.items.fourteen')
        } else if(type == 15) {
            return i.t('constructor:config.slider.items.fifteen')
        } else {
            return ''
        }
    }

    getStoryViewTypeName(type: number|string) {
        if(type == 1) {
            return i.t('constructor:config.storyBlock.items.one')
        } else if(type == 2) {
            return i.t('constructor:config.storyBlock.items.two')
        } else {
            return ''
        }
    }

    getStoryElementViewTypeName(type: number|string) {
        if(type == 1) {
            return i.t('constructor:config.story.items.one')
        } else if(type == 2) {
            return i.t('constructor:config.story.items.two')
        } else if(type == 3) {
            return i.t('constructor:config.story.items.three')
        } else if(type == 4) {
            return i.t('constructor:config.story.items.four')
        } else {
            return ''
        }
    }

    getProductsViewTypeName(type: number|string) {
        if(type == 1) {
            return i.t('constructor:config.blockProduct.items.one')
        } else if(type == 2) {
            return i.t('constructor:config.blockProduct.items.two')
        } else {
            return ''
        }
    }

    sortBlock(action: string, id: number, type: string = '', blockID: number = 0) {
        const data = new FormData()

        if(this.selectedWarehouse > 0) {
            data.append('warehouse_id', String(this.selectedWarehouse))
        }

        if(this.place && type !== 'onboarding') {
            data.append('place', this.place)
        }

        let uri = '/screens/sorting'

        if(type) {
            if(type === 'onboarding') {
                uri = '/onboarding/sorting'
                data.append('is_onboarding', `${Number(this.isOnBoarding)}`)
            } else {
                data.append('type', type)
                if(type === 'media') {
                    data.append('media_id', String(blockID))
                } else  {
                    data.append('item_id', String(blockID))
                }
            }
        }

        data.append('id', String(id))
        data.append('action', action)

        openApi('post', uri, data)
            .then(result => {
                if(this.onBoardingType !== '') {
                    this.getList(this.onBoardingType)
                } else {
                    this.getList()
                }
                notification.success({
                    message: i.t('def:success.success'),
                    description: i.t('def:success.edit')
                })
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    toggleBlock(id: number, type: string) {
        if(!this.openedBlocks?.[type]) this.openedBlocks[type] = []

        let index = this.openedBlocks[type].findIndex((el: any) => el === id)

        if(index > -1) {
            this.openedBlocks[type].splice(index, 1)
        } else {
            this.openedBlocks[type].push(id)
        }
    }
}

export default new __designer()