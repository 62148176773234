import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Row, Col, Button, Select, Switch, Input, InputNumber, Divider, DatePicker } from "antd";
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import controller from '../../settings/project/project.controller'
import ReactQuill from "react-quill";
import { TextEditor } from "../../../helpers/TextEditor";

export const Referral = observer(() => {

  useEffect(() => {
    controller.get()
  }, [])

  const buttons = [
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return controller.isset ? (
    <>
      <form onSubmit={(e) => controller.save(e)}>
        <Titles
          title={i.t('def:referral.title')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col span={24} className="switch-block">
            <div className="element">
              <div></div>
              <Switch
                checked={controller.project.referral_system}
                onChange={e => controller.set('referral_system', e)}
                size="small"
              />
              <div>
                <span>{i.t('def:referral.enable')}</span>
              </div>
            </div>
          </Col>
          <Divider />
          <Col span={24}>
            <label>{i.t('def:referral.description')}</label>
            <TextEditor
              content={controller.project.rf_description}
              returned={(e: any) => controller.project.rf_description = e}
            />

          </Col>
          <Divider />
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <label>{i.t('def:referral.rf_points_inviting_count')}</label>
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={(e) => controller.set('rf_points_inviting_count', e)}
                  value={controller.project.rf_points_inviting_count}
                />
              </Col>
              <Col span={12}>
                <label>{i.t('def:referral.rf_points_inviting_burn')}</label>
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={(e) => controller.set('rf_points_inviting_burn', e)}
                  value={controller.project.rf_points_inviting_burn}
                />
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <label>{i.t('def:referral.rf_points_invited_count')}</label>
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={(e) => controller.set('rf_points_invited_count', e)}
                  value={controller.project.rf_points_invited_count}
                />
              </Col>
              <Col span={12}>
                <label>{i.t('def:referral.rf_points_invited_burn')}</label>
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={(e) => controller.set('rf_points_invited_burn', e)}
                  value={controller.project.rf_points_invited_burn}
                />
              </Col>
              <Col span={24}>
                <label>{i.t('def:referral.rf_condition')}</label>
                <Select
                  value={controller.project.rf_condition}
                  options={[
                    { label: "Заказ выполнен", value: 0 },
                    { label: "Заказ оформлен", value: 1 },
                  ]}
                  onChange={(e: number) => controller.set('rf_condition', e)}
                  style={{ width: `100%` }}
                />
              </Col>
              <Divider />
              <Col span={12}>
                <label>{i.t('def:referral.referral_promo_code_title')}</label>
                <Input
                  value={controller.project.referral_promo_code_title ?? ''}
                  onChange={(e: any) => controller.project.referral_promo_code_title = e.target.value}
                />
              </Col>
              <Col span={12}>
                <label>{i.t('def:referral.referral_promo_code_text')}</label>
                <Input
                  value={controller.project.referral_promo_code_text ?? ''}
                  onChange={(e: any) => controller.project.referral_promo_code_text = e.target.value}
                />
              </Col>
              <Col span={12}>
                <label>{i.t('def:referral.referral_promo_code_share')}</label>
                <Input
                  value={controller.project.referral_promo_code_share ?? ''}
                  onChange={(e: any) => controller.project.referral_promo_code_share = e.target.value}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  ) : <></>
})